@use "styles/colors";
@use "styles/media";

.mainContainer {
  width: 100%;
  max-width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: colors.$background-primary;
  overflow-y: auto;

  .mainContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 auto;
  }
}
