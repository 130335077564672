@use "styles/colors";

.mainContent {
  width: 100%;
  height: 100%;
  background-image: url("/images/404.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .textContent {
    height: auto;
    width: auto;
    display: flex;
    padding: 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: radial-gradient(rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0.0) 100%);

    h2 {
      margin-top: 40px;
      color: white;
      text-shadow: 2px 2px colors.$secondary-color;
    }

    hr {
      width: 100%;
    }

    h2 + p {
      margin: 40px 0;
      text-align: center;
      text-shadow: 2px 2px 2px colors.$secondary-color;
    }

    button {
      margin-top: 24px;
    }
  }
}
